// noinspection JSCheckFunctionSignatures

import React from 'react'

import Login from './views/auth/Login'
import Logout from './views/auth/Logout'
import Dashboard from './views/dashboard'

import { paths } from 'mmfintech-backend-api'
import { LoginStatusEnum } from 'mmfintech-commons-types'

const LazySignup = React.lazy(() => import('./views/auth/Signup'))
const LazyDeposit = React.lazy(() => import('./views/banking/Deposit'))
const LazyInvoices = React.lazy(() => import('./views/invoices/Invoices'))
const LazyCustomers = React.lazy(() => import('./views/customers/Customers'))
const LazyInvoiceEdit = React.lazy(() => import('./views/invoices/InvoiceEdit'))
const LazyTransactions = React.lazy(() => import('./views/transactions'))
const LazyInvoiceUpload = React.lazy(() => import('./views/invoices/InvoiceUploaded'))
const LazyCustomerDetails = React.lazy(() => import('./views/customers/CustomerDetails'))
const LazyInvoiceCheckout = React.lazy(() => import('./views/invoices/InvoiceCheckout'))
const LazyTransactionStatus = React.lazy(() => import('./views/transactions/TransactionFinalStatus'))
const LazySumSubVerification = React.lazy(() => import('./views/onboarding/SumSubVerification'))

const loggedIn = [LoginStatusEnum.LOGGED_IN]

// prettier-ignore
const routes = [
  { path: paths.root(), exact: true, redirect: paths.dashboard() },
  { path: paths.login(), component: Login },
  { path: paths.logout(), component: Logout },
  { path: paths.signUpViaInviteLink(':referralCode'), component: LazySignup },
  { path: paths.signUp(), component: LazySignup },
  { path: paths.forgotPassword(), component: React.lazy(() => import('./views/auth/ForgotPassword')) },
  { path: paths.changePasswordEmail(), component: React.lazy(() => import('./views/auth/ForgotPasswordEmail')) },
  { path: paths.passwordReset(), component: React.lazy(() => import('./views/auth/ResetPasswordEmail')) },
  { path: paths.handlePasswordReset(), component: React.lazy(() => import('./views/auth/ResetPassword')) },
  { path: paths.checkEmail(), component: React.lazy(() => import('./views/auth/CheckEmail')) },
  { path: paths.handleEmailVerify(), component: React.lazy(() => import('./views/auth/VerifyEmail')) },
  { path: paths.handleInvitationVerify(), component: React.lazy(() => import('./views/auth/VerifyInvitation')) },
  { path: paths.handlePasswordResetInvited(), component: React.lazy(() => import('./views/auth/SetPassword')) },

  { path: paths.dashboard(), component: Dashboard, status: loggedIn },
  { path: paths.settings(), component: React.lazy(() => import('./views/settings')), status: loggedIn },
  { path: paths.userManagement(), component: React.lazy(() => import('./views/users')), status: loggedIn },

  { path: paths.banking.depositByAccountId(':accountId'), component: LazyDeposit, status: loggedIn },
  { path: paths.banking.deposit(), component: LazyDeposit, status: loggedIn },
  { path: paths.banking.send(), component: React.lazy(() => import('./views/banking/SendMoney')), status: loggedIn },
  { path: paths.banking.exchange(), component: React.lazy(() => import('./views/banking/Exchange')), status: loggedIn },
  { path: paths.banking.payWithlink(), component: React.lazy(() => import('./views/banking/PayWithLink')) },
  { path: paths.banking.transactions.details(':transactionId'), component: React.lazy(() => import('./views/transactions/TransactionDetails')), status: loggedIn },
  { path: paths.banking.accounts.list(), component: React.lazy(() => import('./views/account/Accounts')), status: loggedIn },
  { path: paths.banking.transactions.listByAccountId(':accountId'), component: LazyTransactions, status: loggedIn },
  { path: paths.banking.transactions.list(), component: LazyTransactions, status: loggedIn },
  { path: paths.banking.expenses(), component: React.lazy(() => import('./views/requests/Requests')), status: loggedIn },

  { path: paths.recipients.details(':recipientId'), component: React.lazy(() => import('./views/recipients/RecipientDetails')), status: loggedIn },
  { path: paths.recipients.list(), component: React.lazy(() => import('./views/recipients/Recipients')), status: loggedIn },

  { path: paths.developer(), component: React.lazy(() => import('./views/developer/Developer')), status: loggedIn },

  { path: paths.contactsInvoices(':payerId'), status: loggedIn, component: LazyCustomerDetails },
  { path: paths.customerInvoices(':payerId'), status: loggedIn, component: LazyCustomerDetails },
  { path: paths.customers(), component: LazyCustomers, status: loggedIn },
  { path: paths.contacts.list(), component: LazyCustomers, status: loggedIn },

  { path: paths.invoices.checkout(':magicToken'), component: LazyInvoiceCheckout },
  { path: paths.invoices.viewByToken(':magicToken'), component: LazyInvoiceCheckout },

  { path: paths.invoices.uploadEdit(':invoiceId'), status: loggedIn, component: LazyInvoiceUpload },
  { path: paths.invoices.upload(), component: LazyInvoiceUpload, status: loggedIn },
  { path: paths.invoices.addForCustomer(':customerId'), status: loggedIn, component: LazyInvoiceEdit },
  { path: paths.invoices.add(), component: LazyInvoiceEdit, status: loggedIn },
  { path: paths.invoices.send(':invoiceId'), component: React.lazy(() => import('./views/invoices/InvoiceSend')), status: loggedIn },
  { path: paths.invoices.edit(':invoiceId'), status: loggedIn, component: LazyInvoiceEdit },
  { path: paths.invoices.listByStatus(':status'), status: loggedIn, component: LazyInvoices },
  { path: paths.invoices.list(), component: LazyInvoices, status: loggedIn },

  { path: paths.onboarding.selectType(), exact: true, component: React.lazy(() => import('./views/onboarding/SumSubSelectType')), status: loggedIn },
  { path: paths.onboarding.sumSub.start(':merchantType'), exact: true, component: LazySumSubVerification, status: loggedIn },
  { path: paths.onboarding.sumSub.continue(), component: LazySumSubVerification, status: loggedIn, exact: true },

  { path: paths.banking.boleto.fail(), exact: true, params: { isSuccess: false, method: 'boleto' }, component: LazyTransactionStatus },
  { path: paths.banking.boleto.success(), exact: true, params: { isSuccess: true, method: 'boleto' }, component: LazyTransactionStatus },
  { path: paths.banking.instantBankTransfer.fail(), exact: true, params: { isSuccess: false, method: 'direct_instant_bank_transfer' }, component: LazyTransactionStatus },
  { path: paths.banking.instantBankTransfer.success(), exact: true, params: { isSuccess: true, method: 'direct_instant_bank_transfer' }, component: LazyTransactionStatus },
  { path: paths.banking.finalStatus.fail(':method'), exact: true, params: { isSuccess: false }, component: LazyTransactionStatus },
  { path: paths.banking.finalStatus.success(':method'), exact: true, params: { isSuccess: true }, component: LazyTransactionStatus },

  { path: '*', component: React.lazy(() => import('./views/auth/Error404')) }
]

export default routes
