import { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { LogoButton, LogoWrapper } from './MerchantLogo.styled'
import { ErrorDisplay, FileInput, Preloader } from 'mmfintech-portal-commons'

import { actions } from 'mmfintech-backend-api'
import { checkImageUpload, convertUploadedImageToBase64, tr, UploadFileError } from 'mmfintech-commons'

import IconLogoRemove from '../../images/icons/logo-delete.svg?react'

export interface MerchantLogoProps {
  readonly?: boolean
}

export const MerchantLogo = ({ readonly }: MerchantLogoProps = {}) => {
  const [logoError, setLogoError] = useState(null)

  const dispatch = useDispatch()

  const { invoiceLogo, merchantLogo, merchantLogoError, merchantLogoFetching } = useSelector(
    ({ user: { merchantLogo, merchantLogoError, merchantLogoFetching }, invoices: { invoiceLogo } }: any) => ({
      invoiceLogo,
      merchantLogo,
      merchantLogoError,
      merchantLogoFetching
    }),
    shallowEqual
  )

  const handleChange = (list: any) => {
    setLogoError(null)
    convertUploadedImageToBase64(list[0], (data: any) => {
      dispatch(actions.merchant.updateLogo(data))
    })
  }

  const handleDelete = () => {
    setLogoError(null)
    dispatch(actions.merchant.deleteLogo())
  }

  const handleError = (errors: UploadFileError) => setLogoError(checkImageUpload(errors))

  return (
    <>
      <LogoWrapper className='logo-wrapper'>
        {merchantLogoFetching ? (
          <div className='logo-loader'>
            <Preloader />
          </div>
        ) : (
          <div className='logo-inner'>
            {readonly ? (
              <LogoButton className='image-item'>
                {merchantLogo ? (
                  <img src={merchantLogo} alt='' data-test='logo-image' />
                ) : invoiceLogo ? (
                  <img src={invoiceLogo} alt='' data-test='logo-image' />
                ) : null}
              </LogoButton>
            ) : (
              <FileInput
                maxNumber={1}
                onChange={handleChange}
                onError={handleError}
                maxFileSize={1024 * 1024}
                acceptType={['jpg', 'jpeg', 'gif', 'png', 'svg']}>
                {({ onFileUpload }) =>
                  merchantLogo ? (
                    <LogoButton className='image-item'>
                      <img src={merchantLogo} alt='' onClick={onFileUpload} data-test='logo-image' />
                      <div className='logo-remove-item' onClick={handleDelete} data-test='logo-delete-button'>
                        <IconLogoRemove />
                      </div>
                    </LogoButton>
                  ) : (
                    <LogoButton className='bordered' onClick={onFileUpload}>
                      <span className='caption'>{tr('FRONTEND.PROFILE.LOGO.UPLOAD', 'Upload Logo')}</span>
                    </LogoButton>
                  )
                }
              </FileInput>
            )}
          </div>
        )}
      </LogoWrapper>

      <ErrorDisplay error={[logoError, merchantLogoError]} className='text-center' />
    </>
  )
}
