import { useContext, useEffect } from 'react'

import { ButtonWrapper } from './NewInvoiceButton.styled'
import { NewInvoiceModal } from '../NewInvoiceModal'

import { actions } from 'mmfintech-backend-api'
import { GlobalContext, isNotEmptyString } from 'mmfintech-commons'

import NewInvoiceIcon from '../../../images/icons/invoice-new.svg?react'

export interface NewInvoiceButtonProps {
  origin?: string
  payerId?: number
}

export const NewInvoiceButton = ({ origin, payerId }: NewInvoiceButtonProps) => {
  const { modalHide, modalShow } = useContext(GlobalContext)

  const handleNewInvoiceClick = () => {
    modalShow({
      options: { maxWidth: 940, size: 'medium' },
      content: <NewInvoiceModal onClose={modalHide} />
    })
  }

  useEffect(() => {
    if (isNotEmptyString(origin)) {
      actions.routing.setInvoiceOrigin(`${origin};${payerId || ''}`)
    } else {
      actions.routing.setInvoiceOrigin(null)
    }
  }, [origin, payerId])

  return (
    <ButtonWrapper data-test='new-invoice-button' onClick={handleNewInvoiceClick}>
      <NewInvoiceIcon />
    </ButtonWrapper>
  )
}
