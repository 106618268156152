import { useHistory } from 'react-router-dom'

import { paths, useOnboardingBanner } from 'mmfintech-backend-api'

const useOnboarding = () => {
  const history = useHistory()

  const startSumSubOnboarding = () => {
    history.push(paths.onboarding.selectType())
  }

  const { startOrContinueOnboarding } = useOnboardingBanner({
    startOnboarding: startSumSubOnboarding
  })

  return {
    startOrContinueOnboarding
  }
}

export default useOnboarding
